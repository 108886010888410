var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"a-aside"},[_c('div',{staticClass:"a-aside__section"},[_c('router-link',{staticClass:"a-aside__title a-aside__title--link",attrs:{"to":{ name: 'mentor-index' }}},[_vm._v("Мои ученики  "),(_vm.showCount)?_c('span',[_vm._v(" ("+_vm._s(_vm.students.length)+" из "+_vm._s(_vm.totalStudents)+")")]):_vm._e()]),_c('div',_vm._l((_vm.students),function(student){return _c('SideBarLink',{key:student.id,attrs:{"to":{
          name: 'mentor-student',
          params: {
            id: student.id,
          },
        },"title":`${student.first_name} ${student.last_name}`}})}),1),(_vm.showCount && _vm.$route.name !== 'mentor-index')?_c('router-link',{staticClass:"link link--pseudo",attrs:{"to":{ name: 'mentor-index' }}},[_vm._v("Все ученики")]):_vm._e(),(!_vm.students.length)?_c('div',[_vm._v(" У вас пока нет учеников "),_c('br'),_c('a',{staticClass:"link link--pseudo",attrs:{"href":"#","to":{ name: 'user-profiles' }},on:{"click":function($event){$event.preventDefault();return _vm.handleInvite.apply(null, arguments)}}},[_vm._v("Пригласить ")])]):_vm._e()],1),_c('a',{staticClass:"a-aside__btn",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleInvite.apply(null, arguments)}}},[_c('BaseIcon',{attrs:{"glyph":"bell"}}),_c('span',[_vm._v("Пригласить ученика")])],1),(_vm.profiles.length)?_c('div',{staticClass:"a-aside__section mt-l"},[_c('div',{staticClass:"a-aside__title"},[_vm._v("Избранные профили")]),_c('div',_vm._l((_vm.profiles),function(profile){return _c('SideBarLink',{key:profile.id,attrs:{"to":{
          name: 'mentor-profile',
          params: {
            id: profile.id,
          },
        },"title":profile.title}})}),1)]):_vm._e(),_c('router-link',{staticClass:"a-aside__btn mb-l",attrs:{"to":{ name: 'mentor-profiles' }}},[_c('BaseIcon',{attrs:{"glyph":"tiles"}}),_c('span',[_vm._v("Профили олимпиады НТО")])],1),_c('ul',{staticClass:"a-aside__links"},[_c('li',[_c('router-link',{attrs:{"to":{ name: 'students-achievements' }}},[_vm._v("Достижения учеников")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'support' }}},[_vm._v("Служба поддержки")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }