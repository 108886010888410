<template>
  <div class="so-modal">
    <h2 class="text-size-h2 text-bold mb-m">Создание организации</h2>

    <p class="mb-m">
      Вы не состоите ни в одной организации. Чтобы продолжить работу в личном
      кабинете Наставника НТО, создайте организацию.
    </p>

    <ValidationObserver
      ref="form"
      tag="form"
      @submit.prevent="handleSubmit">
      <div class="mb-m">
        <label class="form-label">Адрес организации*</label>
        <ValidationProvider
          v-slot="{ errors }"
          mode="aggressive"
          rules="required">
          <AddressAutocomplete
            v-model="org.address"
            placeholder="Укажите город"
            :errors="errors"
            :search-options="{
              from_bound: { value: 'city' },
              locations: [{ country: '*' }],
              to_bound: { value: 'settlement' },
            }" />
        </ValidationProvider>
      </div>
      <div class="mb-l">
        <label
          for="org_name"
          class="form-label"
          >Название организации*</label
        >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required">
          <BaseInput
            id="org_name"
            v-model="org.full_name"
            :errors="errors"
            placeholder="" />
        </ValidationProvider>
      </div>
      <div
        v-if="error"
        class="form-error mb-m">
        {{ error }}
      </div>
      <BaseButton
        type="submit"
        :disabled="pending"
        >Создать организацию</BaseButton
      >
    </ValidationObserver>
  </div>
</template>

<script>
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete";
import { talentRequest } from "@/services/api";
export default {
  name: "SelectOrganizationModal",
  components: {
    AddressAutocomplete,
  },
  data() {
    return {
      pending: false,
      error: "",
      org: {
        full_name: "",
        address: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async handleSubmit() {
      if (this.pending) return;
      this.pending = false;
      this.error = "";
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        this.pending = false;
        return;
      }
      try {
        const { data } = await talentRequest({
          method: "POST",
          url: `/api/users/${this.user.talent_id}/organizations/`,
          data: {
            ...this.org,
            short_name: this.org.full_name,
          },
        });
        if (data.id) {
          this.$store.commit("mentor/SET_STATE", {
            key: "organizations",
            value: [data],
          });

          this.$emit("close");
        }
      } catch (error) {
        this.error = error.message;
      }
    },
  },
};
</script>

<style></style>
