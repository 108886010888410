<template>
  <div class="a-aside">
    <div class="a-aside__section">
      <router-link
        :to="{ name: 'mentor-index' }"
        class="a-aside__title a-aside__title--link"
        >Мои ученики&nbsp;
        <span v-if="showCount">
          ({{ students.length }} из {{ totalStudents }})</span
        ></router-link
      >
      <div>
        <SideBarLink
          v-for="student in students"
          :key="student.id"
          :to="{
            name: 'mentor-student',
            params: {
              id: student.id,
            },
          }"
          :title="`${student.first_name} ${student.last_name}`" />
      </div>
      <router-link
        v-if="showCount && $route.name !== 'mentor-index'"
        :to="{ name: 'mentor-index' }"
        class="link link--pseudo"
        >Все ученики</router-link
      >

      <div v-if="!students.length">
        У вас пока нет учеников <br />
        <a
          href="#"
          class="link link--pseudo"
          :to="{ name: 'user-profiles' }"
          @click.prevent="handleInvite"
          >Пригласить
        </a>
      </div>
    </div>

    <a
      href="#"
      class="a-aside__btn"
      @click.prevent="handleInvite"
      ><BaseIcon glyph="bell" /><span>Пригласить ученика</span></a
    >

    <div
      v-if="profiles.length"
      class="a-aside__section mt-l">
      <div class="a-aside__title">Избранные профили</div>
      <div>
        <SideBarLink
          v-for="profile in profiles"
          :key="profile.id"
          :to="{
            name: 'mentor-profile',
            params: {
              id: profile.id,
            },
          }"
          :title="profile.title" />
      </div>
    </div>

    <router-link
      :to="{ name: 'mentor-profiles' }"
      class="a-aside__btn mb-l"
      ><BaseIcon glyph="tiles" /><span>Профили олимпиады НТО</span></router-link
    >

    <ul class="a-aside__links">
      <li>
        <router-link :to="{ name: 'students-achievements' }"
          >Достижения учеников</router-link
        >
      </li>
      <li>
        <router-link :to="{ name: 'support' }">Служба поддержки</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import SideBarLink from "./SideBarLink";
import InviteUserModal from "@/components/mentor/modals/InviteUserModal";
const MAX_STUDENTS_IN_SIDEBAR = 10;
export default {
  name: "MentorSideBar",
  components: { SideBarLink },
  computed: {
    seasonStudents() {
      if (!this.$store.state.season) return [];
      return Object.values(this.$store.state.mentor.students).filter(
        (student) => student.season_id === this.$store.state.season.id
      );
    },
    students() {
      return Object.values(this.seasonStudents).slice(
        0,
        MAX_STUDENTS_IN_SIDEBAR
      );
    },
    profiles() {
      return Object.values(this.$store.state.mentor.profiles);
    },
    totalStudents() {
      return this.$store.state.mentor.totalStudents;
    },
    showCount() {
      const { totalStudents } = this;
      return totalStudents > MAX_STUDENTS_IN_SIDEBAR;
    },
  },
  methods: {
    handleInvite() {
      this.$modal.show(
        InviteUserModal,
        {},
        { adaptive: true, scrollable: true, minHeight: 400, height: "auto" }
      );
    },
  },
};
</script>

<style lang="less" src="./sidebar.less"></style>
